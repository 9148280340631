body {
  margin: 0;
  /* height: 100vh; */
  /* width: 100vw; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.triangle-loading{
  position: absolute;
  top: 40%;
  left: 45%;
}
label.error{
  color: brown!important;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
}
.loader-container{
  height: 100px;
}
label.errors{
  font: 400 14px/18px 'Open Sans';
  margin: 6px 0 0;
  color: #ff0033;
  display: inline-block;
}