*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html,
body {
    font-family:'Times New Roman', Times, serif;
    font-size: 14px;
}
.splash-screen-wrapper {
    width: 100%;
    height: 100vh;
    background-image: url('../../../public/img/file18-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.center-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
}
.center-logo img {
    width: 220px;
    display: block;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}
.center-logo span {display: block;}
.center-logo .wc-text {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.center-logo .title { 
    font-size: 32px;
}
.action {
    margin-top: 15px;
}
.action .btn-primary{
    border: 1px solid #2A426E;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #2A426E;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 44px;
    line-height: 32px;
    margin-right: 16px;
    display: inline-block;
    text-decoration: none;
}

/* Style the tab */
.tab-list {
    overflow: hidden;
   
  }

.tab-list .tabs {
    padding: 0px !important;
  }
  
  /* Style the buttons inside the tab */
  .tab-list button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .tab-list button:hover {
    background-color: #ddd;
    font-weight: 500;
  }
  
  /* Create an active/current tablink class */
  .tab-list button.active-tabs {
    background-color: #ccc;
    font-weight: 500;
  }
  
  /* Style the tab content */
  .tab-listcontent {
    display: none;
    padding: 17px 12px;
    /* border: 1px solid #ccc; */
    border-top: none;
  }

  .hidden{
    display: none;
  }

  .active-content{
    display: block!important;
}