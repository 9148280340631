/** @font-faces **/
@font-face {
    font-family: 'Roboto';
    src: url('../../../public/fonts/Roboto-Bold.woff2') format('woff2'),
        url('../../../public/fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../public/fonts/Roboto-Medium.woff2') format('woff2'),
        url('../../../public/fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../public/fonts/Roboto-Regular.woff2') format('woff2'),
        url('../../../public/fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Light.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/** Custom Font Icons **/
@font-face {
    font-family: 'files18';
    src:  url('../../../public/fonts/files18.eot?470lm4');
    src:  url('../../../public/fonts/files18.eot?470lm4#iefix') format('embedded-opentype'),
      url('../../../public/fonts/files18.ttf?470lm4') format('truetype'),
      url('../../../public/fonts/files18.woff?470lm4') format('woff'),
      url('../../../public/fonts/files18.svg?470lm4#files18') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .fi {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'files18' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .fi-arrow-left-short:before {
    content: "\e90d";
  }
  .fi-search-black:before {
    content: "\e90e";
  }
  .fi-visibility-black-o:before {
    content: "\e90f";
  }
  .fi-unpublished-black:before {
    content: "\e910";
  }
  .fi-verified-black:before {
    content: "\e911";
  }
  .fi-chevron-right-black:before {
    content: "\e912";
  }
  .fi-checkbox-unchecked:before {
    content: "\e906";
  }
  .fi-form:before {
    content: "\e900";
  }
  .fi-settings-black:before {
    content: "\e901";
  }
  .fi-drive-file-rename-o-black:before {
    content: "\e902";
  }
  .fi-person-black:before {
    content: "\e903";
  }
  .fi-calendar-month-black:before {
    content: "\e904";
  }
  .fi-checkbox-black:before {
    content: "\e905";
  }
  .fi-file-copy-black:before {
    content: "\e907";
    color: #2a426e;
  }
  .fi-visibility-black:before {
    content: "\e908";
    color: #2a426e;
  }
  .fi-file-download:before {
    content: "\e909";
  }
  .fi-close-black:before {
    content: "\e90a";
  }
  .fi-close:before {
    content: "\e90b";
  }
  .fi-upload-documnet:before {
    content: "\e90c";
  }
  
  

/** Reset CSS **/
html, body {
    height: 100%;
}
html{scroll-behavior: smooth;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
body,*{padding:0;margin:0;-webkit-overflow-scrolling:touch;font-family:'Open Sans', sans-serif;box-sizing:border-box;}
ol,ul,li{list-style:none;padding:0;margin:0}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}
a{text-decoration:none;outline:none}
a:focus,img:focus,input:focus,button:focus,div:focus{outline:none;}
*, ::after, ::before {box-sizing:border-box;}
.asterisk {color: #FC0008;}
a, button {cursor: pointer;}
/**  **/
body {
    overflow-x: hidden;
    font-weight: 400;
    background-color: #F0F3F6;
    font-family: 'Open Sans', sans-serif;
}

/** page wrapper **/
.page-wrapper {
    width: 100%;
    position: relative;
}

/** sidebar wrapper **/
.sidebar-wrapper {
    position: fixed;
    top: 0px;
    z-index: 999;
    height: 100vh;
    background: transparent linear-gradient(270deg, #000000 0%, #313131 82%, #545454 100%, #000000 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 10px #FFFFFFAD;
    border-radius: 0px 20px 20px 0px;
    width: 248px;
}

.sidebar-wrapper .logo-segment {
    padding: 30px;
    margin-bottom: 120px;
}
.sidebar-wrapper .logo-segment a {
    width: 169px;
    display: block;
    margin: 0 auto;
}
.sidebar-wrapper .logo-segment a img { 
    width: 100%;
    display: block;
    overflow: hidden;
}
.sidebar-wrapper .sidebar-menu {
    height: calc(100vh - 170px);
    overflow-x: auto;
}
.sidebar-wrapper .sidebar-menu ul li a .fi {
    margin-right: 20px;
    font-size: 18px;
}
.sidebar-wrapper .sidebar-menu ul li a {
    padding: 9px 30px;
    color: #fff;
    display: block;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.25;
}
.sidebar-wrapper .sidebar-menu ul li.menu-active a,
.sidebar-wrapper .sidebar-menu ul li a:hover {
    color: #000;
    background: transparent linear-gradient(90deg, #FFFFFF 0%, #808080 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

/* breadcrumb */
.ms-breadcrumb-w {
    padding-top: 15px;
    padding-bottom: 0;
    background-color: #e9e0fb;
}
.ms-breadcrumb {
    overflow: hidden; 
    display: block; 
    padding: 0; 
    font-family: 'Open Sans', sans-serif;
    margin-left: 0; }
.ms-breadcrumb li {
    float: left;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.ms-breadcrumb li + li::before {
    content: '/';
    padding: 0 5px;
    color: #000000;
}
.ms-breadcrumb li a {
    color: #000000;
    font-family: inherit;
    font-weight: normal;
    font-family: 'PoynterOSDisplay', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.ms-breadcrumb li.active {
    color: #2A426E;
}
@media (max-width: 991px) {
    .ms-breadcrumb-w {
        background-color: #e9e0fb;
    }
}

/* file modal */
/* The Modal (background) */
.file-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .file-modal-content {
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #707070;
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
  }
  .file-modal-head {display: flex; justify-content: space-between; margin-bottom: 20px;}
  .file-modal-head .title {
    color: #1F2937;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  
  /* The Close Button */
  .file-close-action {
    color: #1F2937;
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
  
  .file-close-action:hover,
  .file-close-action:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .file-modal-body .thumb-view {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .file-modal-body .thumb-view img {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

/* main content */
.content-wrapper {
    margin-left: 248px;
}
.page-content {
    min-height: calc(var(--vh, 1vh) * 100 - 132px);
}
.page-header {margin-bottom: 20px;}
.page-header h4 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
}

.card {
    background-color: #fff;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
}
.card-head {
    border-bottom: 1px solid #D1D5DB;
    padding-left: 46px;
    padding-right: 46px;
}
.card-head .tab-list a,
.card-head .tab-list button,
.card-head .tab-list .tablinks {
    padding: 15px 12px 10px;
    font-size: 16px;
    color: #2A426E;
    background-color: transparent;
    border: 0;
    position: relative;
    display: inline-block;
    float: none;
}
.card-head .tab-list a.tab-active:after,
.card-head .tab-list button.tab-active:after { position: absolute; content: ''; height: 4px; background-color: #2A426E; left: 0; width: 100%; bottom: 0; border-radius: 20px;}
.card-head .tab-list a.tab-active,
.card-head .tab-list button.tab-active,
.card-head .tab-list button.tab-active {
    font-weight: 600;
}
.card-body {
    padding: 30px;
}
.form-cap-card {
    border: 1px solid rgba(42, 66, 110, 0.5);
    border-radius: 20px;
    width: calc(50% - 6px);
    padding: 15px;
    position: relative;
}
.add-event-form .form-cap-card {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
}
.form-cap-card .form-caption {
    position: absolute;
    top: -12px;
    left: 10px;
    padding: 0 5px;
    background-color: #fff;
    font-size: 14px;
    color: #1F2937;
}
.form-horizontal .formGroup {display: flex; margin-bottom: 15px; align-items: center; width: 100%;}
.form-regular .formGroup {display: flex; margin-bottom: 15px; justify-content: space-between; align-items: center; width: 100%; }
.form-regular .formGroup .col-6 {
    width: calc(50% - 12px);
}
.form-regular .formGroup .col-12 {
    width: 100%;
}
.form-horizontal .input-label,
.form-regular .input-label {
    width: 160px; 
    overflow-wrap: break-word; 
    margin-right: 24px;
    font-size: 14px;
    color: #1F2937;
}
.formGroup.has-date-icon .right-icon{
    position: relative;
}
.formGroup.has-date-icon .fi {
    position: absolute;
    right: 10px;
    top: 3px;
    color: #1F2937;
}
.form-horizontal .form-wrapper {flex: 1;}
.upload-field .form-wrapper {
    margin-top: 8px;
}
.form-horizontal .input-control,
.form-regular .input-control { 
    min-height: 40px; 
    border: 1px solid rgba(112, 112, 112, 0.5);
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px;
    outline: 0 none;
}
.add-event-form textarea.input-control {
    min-height: 80px;
}
.contributor-basic-details .form-horizontal .formGroup,
.contributor-address .form-horizontal .formGroup {
    align-items: flex-start;
}
.contributor-basic-details .form-horizontal .input-label,
.contributor-address .form-horizontal .input-label {
    margin-top: 12px;
}
.form-horizontal .input-control.w-half {
    width: 50%;
}
.contributor-address .formGroup.has-fig label.error {
    text-align: left;
    align-self: flex-start;
}
.contributor-address .formGroup.has-fig {
    flex-direction: column;
}
.contributor-address .formGroup.has-fig .formInnerGroup {
    display: flex;
    width: 100%;
    align-items: center;
}
.contributor-form .form-action , .contributor-address .form-action {
    margin-top: 60px;
    text-align: center;
}
.contributor-form .submit-btn , .contributor-address .submit-btn {
    background-color: #2A426E;
    border: 0;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #fff;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 44px;
}
/* Contributor : basic details */
.contributor-basic-details .form-col-2,
.contributor-address .form-col-2 {display: flex; justify-content: space-between;}
.contributor-basic-details .form-cap-card,
.contributor-address .form-cap-card {
    margin-bottom: 15px;
}
/* Cpmtributor : address */
.contributor-address  .col-left,
.contributor-address .col-right {
    width: 50%;
    padding: 0 15px;
}
.contributor-address .col-left {
    padding-top: 60px;
}
/* .contributor-address .form-cap-card {width: 100%; margin-left: 0; margin-right: 0;} */
.contributor-address .card-body {padding-top: 20px;}
.contributor-address .pad-same {padding: 0 15px;width: calc(50% - 6px);margin-left: auto;}
.contributor-address .pad-same .toggle-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.contributor-address .pad-same .toggle-checkbox .checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.toggle-checkbox label {color: #2A426E; font-size: 14px; margin-right: 15px;}
.contributor-address .checkbox-toggler {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(42, 66, 110, 0.5);
}
.contributor-address .unchecked,
.contributor-address .checked {
    width: 60px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    color: #2A426E;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}
.contributor-address .unchecked {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.contributor-address .checked {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.contributor-address .checkbox + .checkbox-toggler .unchecked {
    background-color: #2A426E;
    border-color: #2A426E;
    border-radius: 4px;
    color: #fff;
}
.contributor-address .checkbox:checked + .checkbox-toggler .checked {
    color: #fff;
    background-color: #2A426E;
    border-radius: 4px;
}
.contributor-address .checkbox:checked + .checkbox-toggler .unchecked {
    background-color: #fff;
    color: #2A426E;
}
/** Contributor : Doc */
.contributor-form.doc-section .form-col-2 {
    display: flex;
}
.contributor-form.doc-section .help-text{ 
    font-size: 14px; 
    color: #1F2937;
    margin-top: 15px;
    padding: 0 12px;
}
.contributor-form.doc-section .form-cap-card {
    width: calc(100% - 194px);
    padding-top: 30px;
    padding-bottom: 30px;
}
.contributor-form.doc-section table.doc-table {
    width: 100%;
    text-align: left;
    overflow: hidden;
    border-radius: 6px;
}
.pan_no{
    text-transform:uppercase;
}
table.doc-table thead tr th {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(42, 66, 110, 0.2);
    border: 1px solid rgba(112, 112, 112, 0.25);
}
table.doc-table tbody tr td {
    padding: 22px 14px;
    font-size: 14px;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid rgba(112, 112, 112, 0.25);
}
table.doc-table .text-upper {
    text-transform: uppercase;
}
table.doc-table .upload-wrapper {position: relative;}
table.doc-table .file-input-hide {
    position: absolute;
    opacity: 0;
    z-index: -9;
}
table.doc-table .choose-file-btn {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    border: 1px solid #2A426E;
    border-radius: 10px;
    color: #2A426E;
    font-size: 14px;
    padding: 9px 26px;
    background-color: #fff;
    cursor: pointer;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
table.doc-table .file-text {text-transform: uppercase; color: #1F2937; font-weight: 400; font-size: 14px;}
/* Contributor : Declaration */
.contributor-declaration .form-cap-card {
    width: calc(100% - 194px);
    padding: 30px 15px;
}
.contributor-declaration .form-horizontal.form-col-2,
.contributor-declaration .form-cap-card.d-block {display: block;}
.contributor-declaration .form-horizontal {
    margin-bottom: 30px;
}
.contributor-declaration .form-iw {
    display: flex;
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
}
.contributor-declaration .cap-wrapper {
    position: relative;
    border-radius: 10px;
    width: 50%;
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid rgba(112, 112, 112, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contributor-declaration .custom-checkbox-item {margin-bottom: 15px;}
.contributor-declaration .checkbox-content .content {
    color: #1F2937;
    font-size: 14px;
    line-height: normal;
}
.contributor-declaration .checkbox-content .content strong {
    font-weight: bold;
}
.contributor-declaration .upload-wrapper {position: relative;}
.contributor-declaration .file-input-hide {
    position: absolute;
    opacity: 0;
    z-index: -9;
}
.contributor-declaration .choose-file-btn {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    border: 1px solid #2A426E;
    border-radius: 10px;
    color: #2A426E;
    font-size: 14px;
    padding: 9px 26px;
    background-color: #fff;
    cursor: pointer;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.contributor-declaration .file-text {text-transform: uppercase; color: #1F2937; font-weight: 400; font-size: 14px;}
.contributor-declaration .attach-file-wrp {display: flex; align-items: center; background-color: rgba(42, 66, 110, 0.2); border-radius: 10px; width: 100%; padding: 15px;}
.contributor-declaration .preview-data {text-align: center; padding: 30px 15px; color: #1F2937; font-size: 14px;}
.contributor-declaration .preview-data .pre-label {margin-bottom: 5px;}
.image-preview {
    width: 120px;
    height: 120px;
    /* background-color: #D1CACA; */
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
    border: 1px solid #D1CACA;
}
.image-preview1 {
    width: 120px;
    height: 120px;
    /* background-color: #D1CACA; */
    border-radius: 0px;
    margin: 0 auto;
    overflow: hidden;
    border: 0px solid #D1CACA;
}
.doc-form .attach-file-wrp .image-preview {
    width: 120px;
    /* height: 120px; */
    /* background-color: #D1CACA; */
    border-radius: 10px;
    margin-top: 5px;
    overflow: hidden;
}
 .image-preview.pre-signature {
    /* background-color: rgba(209, 202, 202, 0.33); */
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-preview img {width: 120px; height: 120px; object-fit: contain; overflow: hidden; display: block;}
.doc-form .attach-file-wrp .image-preview img {width: 120px; height: auto; object-fit: cover; overflow: hidden;}
 .image-preview.pre-signature img {
    height: 53px;
    width: 120px;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    object-fit: contain;
}
.contributor-declaration .help-text {
    font-size: 14px;
    color: #1F2937;
    font-family: 'Open Sans', sans-serif;
    padding: 20px 12px 0;
    line-height: normal;
}
.contributor-form.doc-form label.error {
    display: block;
}
/* Contributor : Preview */
.page-contributor-preview .card {
    border: 0;
}
.page-contributor-preview .btn-outline {
    border: 1px solid #2A426E;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #2A426E;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 44px;
    margin-right: 16px;
}
.section-basic-details,
.section-address,
.section-doc-upload,
.section-photo-signature {
    display: flex; 
    width: calc(100% - 194px);
    margin-left: -15px;
    margin-right: -15px;
}
.page-contributor-preview .cap-wrapper {
    position: relative;
    padding: 20px;
    border:1px solid rgba(42, 66, 110, 0.5);
    border-radius: 20px;
    width: 50%;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    justify-content: flex-start;
}
.page-contributor-preview .cap-wrapper.w-full {
    width: 100%;
}
.page-contributor-preview .form-caption {
    position: absolute;
    top: -12px;
    background-color: #fff;
    padding: 0 5px;
    left: 15px;
}
.section-photo-signature .error {font-size: 13px; font-family: 'Open Sans', sans-serif;}
.page-contributor-preview .preview-data{
    display: flex;
    margin-bottom: 10px;
}

.preview-component .preview-data{
    display: flex;
    margin-bottom: 10px;
    text-align: left;
    padding: 0px;
}

.preview-btn{
    margin-left: 30px;
}

.page-contributor-preview .pre-label,
.page-contributor-preview .pre-value {
    color: #1F2937;
    font-size: 14px;
}
.page-contributor-preview .pre-label {
    width: 160px;
}
.page-contributor-preview .pre-value {
    font-weight: 600;
}
.page-contributor-preview .section-address .pre-value {
    line-height: 24px;
}
.section-doc-upload .tble-wrapper {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(112, 112, 112, 0.5);
}
.section-doc-upload .thead {
    background-color: rgba(42, 66, 110, 0.2);
    display: flex;
    align-items: center;
}
.section-doc-upload .thead .th {
    color: #1F2937;
    font-size: 14px;
    line-height: normal;
    width: 33.33%;
    font-weight: 600;
    padding: 8px 18px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}
.section-doc-upload .tbody {
    background-color: #fff;
    display: flex;
    min-height: 60px;
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}
.section-doc-upload .tbody:last-child {
    border-bottom: 0;
}
.section-doc-upload .tbody .td {
    color: #1F2937;
    font-size: 14px;
    line-height: normal;
    width: 33.33%;
    font-weight: 400;
    padding: 8px 18px;   
}
.section-doc-upload .text-upper {text-transform: uppercase;}
.section-doc-upload .attachment {display: flex; align-items:center;}
.section-doc-upload .attachment .attach-icon {margin-right: 12px;}
.section-doc-upload .attachment .attach-icon .fi {font-size: 18px;}
.section-photo-signature .ps-wrapper {
    display: flex; 
    margin-top: 20px; 
    margin-left: -15px; 
    margin-right: -15px;
}
.section-photo-signature .image-preview-data,
.section-photo-signature .sign-preview-data {
    text-align: center;
}
.section-photo-signature .image-preview-data .label {
    margin-bottom: 5px;
}
.section-photo-signature .image-preview-data .image-preview {
    width: 120px;
}
.section-photo-signature .sign-preview-data .label {
    margin-bottom: 5px;
    color: #1F2937;
}
.section-photo-signature .sign-preview-data .image-preview {
    width: 213px;
    display: flex;
    align-items: center;
}
.section-photo-signature .image-preview-data .image-preview,
.section-photo-signature .sign-preview-data .image-preview {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 6px;
    height: 139px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}
.section-photo-signature .sign-preview-data .image-preview img {
    width: 213px;
    height: 92px;
    margin: 0 auto;
    overflow: hidden;
    object-fit: contain;
}
.section-photo-signature .image-preview-data .image-preview img {
    width: 120px;
    height: 136px;
    display: block;
    margin: 0 auto;
    object-fit: contain;
}
.page-contributor-preview .contributor-form .form-action {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* form */
.custom-checkbox-item .checkbox {
    position: absolute; 
    opacity: 0; 
    z-index: -1;
}
.custom-checkbox-item .checkbox:checked ~ .checkbox-content .fi-checkbox-unchecked {
    color: #2A426E;
}
.custom-checkbox-item .checkbox:checked ~ .checkbox-content .fi-checkbox-unchecked:before {
    font-family: 'files18' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e905";
}
.custom-checkbox-item .checkbox-content { display: flex;  cursor: pointer;}
.custom-checkbox-item .icon-holder { margin-top: 2px; margin-right: 15px;}
.custom-checkbox-item .icon-holder .fi { color: #707070;}


/* Reports Page */
.page-reporters .search-filter {
    padding: 15px;
}
.page-reporters .search-form {
    width: 100%;
    max-width: 600px;
    position: relative;
}
.page-reporters .search-form .input-control {
    min-height: 40px; 
    border: 1px solid #6B7280;
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px 1px 38px;
    outline: 0 none;
    color: #1F2937;
    font-size: 14px;
}
.page-reporters .search-form .input-control::placeholder {
    color: #1F2937;
}
.page-reporters .search-form .icon-left {
    position: absolute;
    top: 10px;
    left: 10px;
}
.page-reporters .search-form .icon-left i { 
    color: #1F2937;
}
.page-reporters .card-body {
    padding: 0;
}
.page-reporters .table {width: 100%; margin-bottom: 15px;}
.page-reporters .table thead tr th {
    background-color: #D4D9E2;
    text-align: left;
    color: #000;
    font-weight: 600;
}
.page-reporters .table thead tr th,
.page-reporters .table tbody tr td {
    font-size: 14px;
    padding: 12px;
}
.page-reporters .table tbody tr td {
    border-bottom: 1px solid #D1D5DB;
    color: #1F2937;
}
.page-reporters .table tbody tr:last-child td {
    border-bottom: 0;
}
.page-reporters .table tbody tr td.action,
.page-reporters .table thead tr th.action {
    text-align: center;
}
.page-reporters .action-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    color: #000;
}
.page-reporters .pagination-strip {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    margin-bottom: 20px;
}
.page-reporters .pagination-strip span {
    font-size: 14px;
}
.page-reporters .pagination-strip input {
    text-align: center;
    font-size: 14px;
    color: #272D3B;
    max-width: 80px;
    width: 100%;
    height: 36px;
    border-radius: 10px;
    border: 1px solid rgba(112, 112, 112, 0.68);
}
.page-reporters .pg-buttons { 
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    gap: 20px;
}
.page-reporters .pg-buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.page-reporters .pg-buttons button.disabled {
    opacity: 0.5;
}

/* reporters details */
.page-reporter-details .rd-section .section-title {
    color: #1F2937;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
}
.page-reporter-details .table {
    width: 100%; 
    margin-bottom: 15px; 
    /* border-collapse: separate; */
    overflow: hidden;
    /* border-radius: 10px;  */
}
.page-reporter-details .table thead tr th {
    background-color: #D4D9E2;
    color: #000;
    font-weight: 600;
    border-collapse: collapse;
    border: 1px solid #94A0B6;
    
}
.page-reporter-details .table thead tr th,
.page-reporter-details .table tbody tr td {
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    padding: 12px 15px;
}
.page-reporter-details .table tbody tr td {
    border: 1px solid #94A0B6;
    font-size: 14px;
    font-weight: 600;
    padding: 15px;
    color: #1F2937;
}
.page-reporter-details .table tbody tr td.address {
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
}
.page-reporter-details .reporter-tt-w {
    text-align: center;
}
.reporter-thumb {
    border-radius: 6px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 94px;
    height: 86px;
    overflow: hidden;
    margin: 0 auto 5px;
    border: 1px solid #94A0B6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-reporter-details .reporter-name {
    font-size: 14px;
    font-weight: 600;
    color: #1F2937;
}
.reporter-thumb img {
    width: 108px;
    height: 108px;
    display: block;
    overflow: hidden;
    object-fit: contain;
}
.page-reporter-details .rd-table .input-control {
    min-height: 40px; 
    border: 1px solid rgba(112, 112, 112, 0.5);
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px;
    outline: 0 none;
}
.page-reporter-details .rd-table select.input-control {
    background-color: #fff;
    padding: 10px 12px;
}
.page-reporter-details .form-action {
    text-align: center;
    margin-top: 60px;
}
.page-reporter-details .submit-btn {
    background-color: #2A426E;
    border: 0;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #fff;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 44px;
}

/* management details */
.page-reporter-details .table tbody tr td .created-date {
    font-size: 14px;
    margin-top: 5px;
    color: #1F2937;
    font-style: italic;
    font-weight: 400;
}
.page-reporter-details .md-section {
    margin-top: 30px;
    width: 100%;
    max-width: calc(100%);
}
.page-reporter-details .md-section .manage-desig-val {
    text-transform: capitalize;
}
.page-reporter-details .table thead tr th.text-left,
.page-reporter-details .table tbody tr td.text-left {
    text-align: left;
}
.md-section .formGroup .input-control {
    width: 130px;
}
.md-section .formTextarea textarea {
    resize: none;
    width: 220px;
    border: 1px solid rgba(112, 112, 112, 0.5);
    height: 70px;
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px;
    outline: 0 none;
}
.page-reporter-details .submit-btn.btn-outline {
    border: 1px solid #2A426E;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #2A426E;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 44px;
    margin-right: 16px;
}
.management-details .md-section .formTextarea textarea {
    height: 96px;
    padding-top: 10px;
}
.page-reporter-details.management-details .table thead tr th,
.page-reporter-details.management-details .table tbody tr td {
    vertical-align: middle;
}
.page-reporter-details.management-details .md-section .table tbody tr td {
    vertical-align: top;
}
/* Contract Copy */
.page-reporter-details .upload-section {
    margin-top: 30px;
}
.page-reporter-details .rd-section.upload-section .section-title {
    margin-bottom: 10px;
}
.upload-contract-copy {width: 100%; max-width: 410px;}
.upload-contract-copy label {
    color: #1F2937;
    font-size: 14px;
    margin-bottom: 5px;
    display: inline-block;
}
.upload-contract-copy .upload-cc-wrapper {position: relative;}
.upload-contract-copy .upload-cc-wrapper .file-input-hide {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.upload-contract-copy .drop-files {
    display: flex;
    border: 1px solid #2A426E;
    padding: 6px 12px 3px;
    border-radius: 10px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
}
.upload-contract-copy .drop-files .icon-holder {
    margin-right: 10px;
}
.upload-contract-copy .drop-files .icon-holder .fi {
    font-size: 34px;
    color: #2A426E;
}
.upload-contract-copy .drop-files .text {font-weight: 500; color: #000;}
.upload-contract-copy .drop-files .text-blue {
    color: #2A426E;
    text-decoration: underline;
}
.upload-contract-copy .temp-upload-file {
    display: flex;
    border: 1px solid #2A426E;
    width: 300px;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #2A426E;
    font-weight: 500;
    align-items: center;
}
.upload-contract-copy .temp-upload-file .icon-holder {
    margin-right: 10px;
}
.upload-contract-copy .temp-upload-file .icon-holder .fi {
    font-size: 22px;
}
.upload-contract-copy .upload_file_name {
    word-break: break-all;
}
.remove-icon {
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
}

.upload-contract-copy .temp-upload-file .download-icon {
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
}
.ar-uploaded-section .upload-cc-wrapper {
    display: flex;
    align-items: center;
}
.ar-uploaded-section .upload-cc-wrapper .reason-sel {
    position: relative;
    width: 100%;
    max-width: 250px;    
}
.ar-uploaded-section .upload-cc-wrapper .reason-sel label {
    position: absolute;
    left: 0;
}
.ar-uploaded-section .upload-contract-copy {
    max-width: initial;
}
.ar-uploaded-section .formGroup .input-control {
    min-height: 40px; 
    border: 1px solid rgba(112, 112, 112, 0.5);
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px;
    outline: 0 none;
}
.ar-uploaded-section .other-reason {
    width: 100%;
    max-width: 260px;
}
.ar-toggle-button .check-w input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.ar-uploaded-section .upload-contract-copy label {
    margin-bottom: 0;
}
.upload-cc-wrapper .checkbox-toggler {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(42, 66, 110, 0.5);
}
.upload-cc-wrapper .unchecked,
.upload-cc-wrapper .checked {
    width: 70px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    color: #2A426E;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}
.upload-cc-wrapper .unchecked {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.upload-cc-wrapper .checked {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.upload-cc-wrapper .checkbox + .checkbox-toggler .unchecked {
    background-color: #2A426E;
    border-color: #2A426E;
    border-radius: 4px;
    color: #fff;
}
.upload-cc-wrapper .checkbox:checked + .checkbox-toggler .checked {
    color: #fff;
    background-color: #2A426E;
    border-radius: 4px;
}
.upload-cc-wrapper .checkbox:checked + .checkbox-toggler .unchecked {
    background-color: #fff;
    color: #2A426E;
}
.ar-uploaded-section .upload-contract-copy .temp-upload-file,
.ar-uploaded-section .upload-contract-copy .ar-toggle-button,
.ar-uploaded-section .upload-contract-copy .reason-sel {
    margin-right: 15px;
}
/* Admin : Reporter Details */
.admin-tbl-wrapper {
    border: 1px solid rgba(112, 112, 112, 0.25);
    overflow: hidden;
    border-radius: 6px;
}
.table.admin-report-tbl {
    width: 100%;
    text-align: left;
}
.table.admin-report-tbl thead tr th {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(42, 66, 110, 0.2);
    border-bottom: 1px solid rgba(112, 112, 112, 0.25);
}
.table.admin-report-tbl tbody tr td {
    font-size: 14px;
    padding: 8px 14px;
    border-bottom:  1px solid rgba(112, 112, 112, 0.25);
}
.admin-reporter-details .formGroup .input-control {
    min-height: 34px; 
    border: 1px solid rgba(112, 112, 112, 0.5);
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px;
    outline: 0 none;
}
.ar-uploaded-section .other-reason {
    width: 100%;
    max-width: 260px;
}
.admin-report-tbl .ar-toggler,
.admin-report-tbl .ar-sel-reason {margin-right: 12px;}
.table.admin-report-tbl tbody tr:last-child td {border-bottom: 0;}
.admin-reporter-details .section-doc-upload {width: 100%;}

.admin-reporter-details .section-doc-upload .tbody .td:last-child { flex: 1; }
.section-doc-upload .admin-ar-doc .attachment {width: 220px; min-width: 220px; align-items: center;}
.admin-ar-doc {display: flex;}
.admin-reporter-details input[type="checkbox"] {
    position: absolute;
    z-index: -1;
}
.admin-reporter-details .checkbox-toggler {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(42, 66, 110, 0.5);
    width: 142px;
}
.admin-reporter-details .unchecked,
.admin-reporter-details .checked {
    width: 70px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    color: #2A426E;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}
.admin-reporter-details .unchecked {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.admin-reporter-details .checked {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.admin-reporter-details .checkbox + .checkbox-toggler .unchecked {
    background-color: #2A426E;
    border-color: #2A426E;
    border-radius: 4px;
    color: #fff;
}
.admin-reporter-details .checkbox:checked + .checkbox-toggler .checked {
    color: #fff;
    background-color: #2A426E;
    border-radius: 4px;
}
.admin-reporter-details .checkbox:checked + .checkbox-toggler .unchecked {
    background-color: #fff;
    color: #2A426E;
}
.admin-ar-ps {display: flex; align-items: flex-start; justify-content: space-between; margin-top: 20px; flex-wrap: wrap; margin-top: 20px;}
.admin-ar-ps .ar-toggler {
    padding-right: 7.5px;
    width: 154px;
    max-width: 154px;
}
.admin-ar-ps .ar-sel-reason {
    padding-left: 7.5px;
    max-width: calc(100% - 154px);
    width: calc(100% - 154px);
}
.admin-ar-ps .ar-other-reason {
    width: 100%;
}
.admin-ar-ps .ar-sel-reason .formGroup,
.admin-ar-ps .ar-other-reason .formGroup {
    position: relative;
}
.admin-ar-ps .ar-toggler,
.admin-ar-ps .ar-sel-reason {
    margin-right: 0;
    /* width: 50%; */
    margin-bottom: 20px;
}
.ar-sel-reason label.error,
.admin-ar-ps .ar-other-reason label.error {
    /* position: absolute;
    left: 0;
    bottom: -18px; */
}

.admin-report-tbl .attachment {margin-right: 12px;}
.admin-report-tbl .attachment .attachment-name {text-overflow: ellipsis; overflow: hidden;}
.admin-report-tbl .attachment .view-icon {margin-left: auto; cursor: pointer; padding: 0 5px;}
.admin-report-tbl .attachment-name {
    position: relative;
    padding-right: 44px;
    flex: 1;
    width: 200px;
    max-width: 200px;
}
.admin-report-tbl .attachment-action {
    position: absolute;
    right: 0;
    top: 50%;
    display: flex;
    margin-top: -11px;
}
.admin-report-tbl .attachment-action .download-icon {color: #22831c;}
.admin-report-tbl .attachment-action .download-icon, .admin-report-tbl .attachment-action .fi-visibility-black {
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
}
.admin-reporter-details .submit-btn {width: auto; min-width: 166px; padding-left: 32px; padding-right: 24px;}

/* super admin */ 
.page-staff-list .search-filter {
    padding: 15px;
}
.page-staff-list .search-form {
    width: 100%;
    max-width: 460px;
    position: relative;
}
.page-staff-list .search-form .input-control {
    min-height: 40px; 
    border: 1px solid #6B7280;
    border-radius: 10px;
    width: 100%;
    padding: 1px 12px 1px 38px;
    outline: 0 none;
    color: #1F2937;
    font-size: 14px;
}
.page-staff-list .search-form .input-control::placeholder {
    color: #1F2937;
}
.page-staff-list .search-form .icon-left {
    position: absolute;
    top: 10px;
    left: 10px;
}
.page-staff-list .search-form .icon-left i { 
    color: #1F2937;
}
.page-staff-list .card-body {
    padding: 0;
}
.page-staff-list .table {width: 100%; margin-bottom: 15px;}
.page-staff-list .table thead tr th {
    background-color: #D4D9E2;
    text-align: left;
    color: #000;
    font-weight: 600;
}
.page-staff-list .table thead tr th,
.page-staff-list .table tbody tr td {
    font-size: 14px;
    padding: 12px;
}
.page-staff-list .table tbody tr td {
    border-bottom: 1px solid #D1D5DB;
    color: #1F2937;
}
.page-staff-list .table tbody tr:last-child td {
    border-bottom: 0;
}
.page-staff-list .table tbody tr.tr_active td {border-bottom: 0;}
.page-staff-list .table tbody tr td:first-child {
    width: 38px;
}
.page-staff-list .tr_active td:first-child {
    position: relative;
    border-left-color: #0A0A0A;
}
.page-staff-list .tr_active td:first-child:before {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 100%;
    top: 0;
    background-color: #0A0A0A;
    border-top-left-radius: 10px;
}
.page-staff-list .tr_toggle {display: none;}
.page-staff-list .tr_active + .tr_toggle {display: table-row; width: 100%;}
.page-staff-list .tr_active + .tr_toggle td {
    background-color: rgba(212, 217, 226, 0.5);
    border-bottom-color: rgba(212, 217, 226, 0.5);
}
.page-staff-list .tr_active + .tr_toggle td:first-child {
    position: relative;
    border-left-color: #0A0A0A;
}
.page-staff-list .tr_active + .tr_toggle td:first-child:before {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 100%;
    top: 0;
    background-color: #0A0A0A;
    border-bottom-left-radius: 10px; 
}
.page-staff-list .tr_active .tr_toggle_action .fi {
    transform: rotate(90deg);
    display: block;
}
.page-staff-list .tr_toggle_action {
    width: 24px; 
    height: 24px; 
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 24px;
}
.page-staff-list .tr_toggle_action .fi {
    font-size: 12px;
    color: #0A0A0A;
    line-height: 24px;
}
.page-staff-list .tr_toggle .table-details {
    display: flex;
    justify-content: space-between;
    padding-left: 48px;
    padding-right: 48px;
    align-items: center;
}
.page-staff-list .date {
    color: #3E3E3E;
    font-size: 16px;
    font-weight: 600;
}
.page-staff-list .date + .date{
    margin-top: 8px;
}
.page-staff-list .date .label {
    margin-right: 8px;
}
.page-staff-list .date .val{
    font-size: 14px;
    font-weight: 400;
}
.deactive-btn {margin-right: 30px;}
.deactive-btn,
.page-staff-list .tr_toggle .edit-btn {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    border: 1px solid #2A426E;
    border-radius: 10px;
    height: 38px;
    width: 140px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    color: #2A426E;
}
.page-staff-list .table tbody tr td.action,
.page-staff-list .table thead tr th.action {
    text-align: center;
}
.page-staff-list .action-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    color: #000;
}
.page-staff-list .pagination-strip {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    margin-bottom: 20px;
}
.page-staff-list .pagination-strip span {
    font-size: 14px;
}
.page-staff-list .pagination-strip input {
    text-align: center;
    font-size: 14px;
    color: #272D3B;
    max-width: 80px;
    width: 100%;
    height: 36px;
    border-radius: 10px;
    border: 1px solid rgba(112, 112, 112, 0.68);
}
.page-staff-list .pg-buttons { 
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    gap: 20px;
}
.page-staff-list .pg-buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.page-staff-list .pg-buttons button.disabled {
    opacity: 0.5;
}
.search-filter {
    display: flex;
}
.search-filter .search-form {
    margin-right: 20px;
}
.search-filter .add-staff-btn {
    background-color: #2A426E;
    border: 0;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #fff;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 40px;
}

/* super admin add staff */
.staff-modal {
    display: none;
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .staff-modal-content {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px; /* Could be more or less, depending on screen size */
    border-radius: 20px;
    overflow: hidden;
    max-height: 100vh;
    padding-top: 48px;
  }
  .staff-mhead {
    display: flex; 
    justify-content: space-between; 
    margin-bottom: 20px;
    background-color: #D4D9E2;
    align-items: center;
    padding: 12px 15px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}
  .staff-mhead .staff-mtitle {
    color: #272D3B;
    font-size: 18px;
    font-weight: 600;
  }
  
  /* The Close Button */
  .staff-mclose {
    color: #272D3B;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0.6;
  }
  
  .staff-mclose:hover,
  .staff-mclose:focus {
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
  }
  .staff-mbody {
    padding: 15px;
    max-height: 500px;
    overflow-y: auto;
  }
  .staff-mbody .staff-form {
    width: calc(100% - 120px);
  }
  .staff-mbody label {
    width: 140px;
    font-size: 14px;
    color: #1F2937;
  }
  .staff-mbody .error {
    height: auto;
    width: 100%;
    font-size: 13px;
    color: brown!important;
  }
  .staff-mbody .form-horizontal .formGroup {
    align-items: flex-start;
  }
  .staff-mbody .form-horizontal .formGroup label {
    margin-top: 12px;
  }
  .staff-form .form-wrapper {
    width: 100%;
    flex: 1;
  }
  .staff-form.form-horizontal .input-control {
    min-height: 38px;
  }
  .staff-mbody .form-action {
    text-align: center;
    margin-top: 30px; 
    margin-bottom: 15px;
  }
  .staff-mbody .submit-btn {
    background-color: #2A426E;
    border: 0;
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.42);
    width: 166px;
    font-size: 16px;
    color: #fff;
    padding: 6px 13px;
    text-transform: uppercase;
    height: 40px;
  }

/* Vendor : Track Form Status */
.page-track-status .form-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
.page-track-status .progress-bar {
    display: block;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 60px;
}
.page-track-status .progress-bar ul {
    white-space: nowrap;
    overflow-y: auto;
}
.page-track-status .progress-bar li:first-child {
    padding-left: 0;
}
.page-track-status .progress-bar li:first-child {
    padding-right: 0;
}
.page-track-status .progress-bar li {
    padding-top: 38px;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 130px;
    vertical-align: top;
}
.page-track-status .progress-bar li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #fff;
    border: 2px solid #2A426E;
    z-index: 2;
}
.page-track-status .progress-bar li:after {
    content: '';
    position: absolute;
    top: 10.5px;
    width: 100%;
    left: -50%;
    /* border: 1px dashed #000; */
    background-image: linear-gradient(to right, #2A426E 45%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 15px 2px;
    background-repeat: repeat-x;
    height: 2px;
}
.page-track-status .progress-bar li:first-child:after {
    content: none;
}
.page-track-status .progress-bar li .label {
    font-size: 14px;
    color: #000;
    opacity: 0.5;
}
.page-track-status .progress-bar li.completed .label {
    font-weight: 600;
    opacity: 1;
}
.page-track-status .progress-bar li.completed+li:after,
.page-track-status .progress-bar li.active-stage+li:after {
    background-image: linear-gradient(to right, #2A426E 100%, rgba(255, 255, 255, 0) 0%);
}
.page-track-status .progress-bar li.completed:before {
    background-color: #2A426E;
}

.page-track-status .section-doc-upload,
.page-track-status .section-photo-signature {
    width: 100%;
}
.page-contributor-preview.page-track-status .ps-wrapper {width: 100%; align-items: center;}
.page-contributor-preview.page-track-status .ps-wrapper .cap-wrapper {
    /* height: 205px; */
}
.page-contributor-preview.page-track-status .upload-wrapper {
    margin-top: 10px;
}
.page-contributor-preview.page-track-status .ps-wrapper .cap-wrapper,
.page-track-status .section-photo-signature .ps-status {width: 33.33%;}
.page-track-status .section-photo-signature .ps-status {
    /* text-align: center; */
    color: #1F2937;
    font-size: 14px;
}

/* status color */
.status-label {
    color: #1F2937;
    font-size: 14px;
    display: flex; 
    align-items: center;
    justify-content: center;
}
.status-label .fi {
    font-size: 18px;
    margin-right: 10px;
}
.status-label.accepted .fi {
    color: #22831c;
}
.status-label.rejected .fi {
    color: #ef3838;
}

.page-track-status .section-photo-signature .ps-status .status-label {
    justify-content: flex-start;
}
.section-doc-upload .status-label {
    justify-content: flex-start;
}

/* upload files */
.page-track-status .section-doc-upload .upload-wrapper {position: relative;}
.page-track-status .section-doc-upload .file-input-hide {
    position: absolute;
    opacity: 0;
    z-index: -9;
}
.page-track-status .section-doc-upload .choose-file-btn {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    border: 1px solid #2A426E;
    border-radius: 10px;
    color: #2A426E;
    font-size: 14px;
    padding: 9px 26px;
    background-color: #fff;
    cursor: pointer;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    display: inline-block;
}
.page-track-status .section-doc-upload .file-text {text-transform: uppercase; color: #1F2937; font-weight: 400; font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.page-track-status .section-doc-upload .attach-file-div {
    width: 47%;
}
.page-track-status .section-cc .section-title {
    color: #1F2937;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
}
.page-track-status .section-cc .label {
    color: #1F2937;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
}
.page-track-status .contract-wrapper {
    display: flex;
}
.page-track-status .contract-wrapper .col-left {
    margin-right: 60px;
}
.page-track-status .upload-contract-copy {
    max-width: initial;
}
.page-track-status .upload-contract-copy label {
    margin-bottom: 8px;
}
.page-track-status .upload-cc-wrapper {
    max-width: 410px;
    width: 100%;
}
.page-track-status .section-cc .download-file {
    display: flex;
    border: 1px solid #2A426E;
    width: 300px;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #2A426E;
    font-weight: 500;
    align-items: center;
}
.page-track-status .section-cc .download-file .icon-holder {
    margin-right: 10px;
}
.page-track-status .section-cc .download-file .icon-holder .fi {
    font-size: 22px;
    color: #2a426e;
}
.remove-icon {
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
}
.page-track-status .section-cc .download-file .download-icon {
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
    color: #2a426e;

}
.page-track-status .upload_file_name {
    word-break: break-all;
}




/* Media */
.file-header{display: none;}
.overlay{display: none;}
.m-pad{display: none;}
.v-mob {display: none;}
.h-mob {display: block;}
@media (min-width: 768px) {
    .page-content {
        padding: 20px;
    }
}
/** responsive **/
@media (max-width: 1050px) {
    .content-wrapper {
        margin-left: 0;
    }
    .page-content {
        padding: 20px;
    }
    .card-body {
        padding: 15px;
    }
}

@media (max-width: 920px) {
    .file-header{display: flex;position: sticky;top:0;background-color:#F0F3F6;padding: 0 16px;align-items: center;justify-content: space-between;z-index: 9}
    .nw-logo{width: 65px;height: 42px;}
    .hamburger-icon{display: inline-block;cursor: pointer;}
    .bar1, .bar2, .bar3 {width: 30px;height: 3px;background-color: #033333;margin: 6px 0;transition: 0.4s;}
    .change .bar1 {transform: translate(0, 6px) rotate(-45deg);}
    .change .bar2 {opacity: 0;}
    .change .bar3 {transform: translate(0, -11px) rotate(45deg);}
    .page-content {padding: 0;}
    .page-header{margin-bottom: 5px;}
    .sidebar-wrapper{width: 0px;transition:.5s all;top: 43px;}
    .sidebar-width{width: 80%;}
    .logo-segment{display: none;}
    .content-wrapper{margin-left: 0;background-color: #fff;position: relative;}
    .page-header h4{background-image: linear-gradient(to right, #545454, #313131, #000000);color: #fff;padding: 18px 28px;font-size: 16px;line-height: 24px;}
    .page-header .breadcrumb-w {
        padding: 18px 28px;
    }
    .card{border:0;}
    .card-head{padding: 0 10px;}
    .tab-list{white-space: nowrap;overflow-x: auto;}
    .card-body{padding: 20px 15px;}
    .form-cap-card{width: 100%;margin: 0;}
    .contributor-basic-details .form-col-2, .contributor-address .form-col-2{flex-direction: column;}
    .contributor-basic-details .form-cap-card{margin-bottom: 35px;}
    .contributor-basic-details .form-cap-card:last-child { margin-bottom: 30px;}
    .contributor-address .contributor-form .form-action {margin-top: 15px;}
    .form-horizontal .formGroup{flex-direction: column;align-items: flex-start;}
    .contributor-address .formGroup.has-fig .formInnerGroup{
        flex-direction: column;
        align-items: start;
    }
    .form-horizontal .formGroup.mob-two-col {flex-direction: row;}
    .form-horizontal .input-control.w-half{width: 40%;}
    .form-horizontal .form-wrapper,
    .form-horizontal .formGroup.mob-two-col .input-control,
    .form-horizontal .m-formGroup .input-control.w-half {width: 100%;}
    .form-horizontal .formGroup.mob-two-col {align-items: center;}
    .form-horizontal .formGroup.mob-two-col .input-label {margin-bottom: 0;}
    .form-horizontal .m-formGroup{flex-direction: row;}
    .form-horizontal .input-label{margin-right: 0;font-size: 14px;line-height: 22px;margin-bottom: 10px;}
    .form-cap-card .form-caption{font-size: 16px;line-height: 22px; left: 25px;}
    .form-horizontal .input-control{font-size: 16px;line-height: 19px;}
    .overlay{display:none;width: 100%;height: 100%;background-color: #000;opacity: .5;position: absolute;top:0;z-index: 1;}
    .show-overlay{display: block;}
    .contributor-form .form-action{margin-top: 0;}
    .contributor-form.doc-form{display: flex;flex-direction: column;}
    .contributor-form.doc-section .form-action{margin-top: 30px;}
    .contributor-address .col-left {padding-top: 0;}
    .contributor-address .col-left,
    .contributor-address .col-right {width: 100%; padding: 0;}
    .contributor-address .pad-same .toggle-checkbox{align-items: flex-start;}
    .contributor-address .pad-same .toggle-checkbox .check-w {margin-right: 10px;}
    .contributor-address .checkbox-toggler{width: 120px;}
    .toggle-checkbox label{font-size: 16px;line-height: 22px;margin-right: 0;margin-bottom: 5px;}
    .contributor-address .pad-same .toggle-checkbox .checkbox{z-index: 1;left: 20%;}
    .contributor-basic-details .form-horizontal .input-label,
    .contributor-address .form-horizontal .input-label {margin-top: 0;}
    .contributor-basic-details .form-horizontal .formGroup.m-formGroup .input-label,
    .contributor-address .form-horizontal .formGroup.m-formGroup .input-label {margin-top: 12px;}
    .d-pad{display: none;}
    .m-pad{display: block;}
    .v-mob {display: block;}
    .h-mob {display: none;}
    .contributor-form.doc-section .form-col-2 {flex-direction: column;}
    .contributor-form.doc-section .help-text.v-mob,
    .contributor-declaration .help-text.v-mob {padding-top: 0; margin-top: 0; margin-bottom: 20px;}
    .contributor-form.doc-section .form-cap-card{width: 100%; padding: 0;}
    .contributor-form.doc-section .doc-table thead {
        display: none;
    }
    .contributor-form.doc-section .doc-table tr {
        border-bottom: 1px solid rgba(112, 112, 112, 0.25);
    }
    .contributor-form.doc-section .doc-table tr td {
        display: block;
        border: 0;
        background-color: transparent;
        font-weight: 400;
        margin-bottom: 0;
        padding: 15px;
        padding-bottom: 0;
    }
    .contributor-form.doc-section .doc-table tr td:last-child {
        padding-bottom: 15px;
    }
    .contributor-form.doc-section table.doc-table .choose-file-btn {
        display: inline-block;
    }
    .contributor-form.doc-section .tab-label {
        font-size: 14px;
        font-weight: 600;
        color: #1F2937;
        margin-bottom: 10px;
    }
    .contributor-declaration .form-cap-card {
        width: 100%;
        padding: 0;
    }
    .contributor-declaration .form-iw {
        flex-direction: column;
        margin: 0;
    }
    .contributor-declaration .cap-wrapper {
        width: 100%;
        margin: 0;
        border: 0;
        border-radius: 0;
        padding: 20px;
    }
    .contributor-declaration .cap-wrapper:first-child { 
        border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    }
    .contributor-declaration .form-cap-card .form-iw .form-caption {
        position: static;
        background-color: transparent;
        padding: 0;
        font-weight: 600;
    }
    .contributor-declaration .d-form-check .form-cap-card {
        padding: 20px;
    }
    .contributor-declaration .form-cap-card .form-iw .preview-data {
        padding: 0;
    }
    .contributor-declaration .attach-file-wrp {
        flex-direction: column;
        border-radius: 0;
        background-color: transparent;
        padding-bottom: 0;
    }
    .contributor-declaration .choose-file-btn {
        margin: 0;
        margin-bottom: 10px;
    }
    .contributor-address .pad-same {width: 100%;}

    /* Preview */
    .section-basic-details,
    .section-address,
    .section-doc-upload, .section-photo-signature {
        width: 100%;
        margin: 0;
        flex-direction: column;
    }
    .page-contributor-preview .cap-wrapper {
        margin: 0 0 30px;
        width: 100%;
        border: 1px solid rgba(42, 66, 110, 0.5);
        border-radius: 20px;
    }
    .page-contributor-preview .page-header {
        margin-bottom: 30px;
    }
    .page-contributor-preview .pre-value {
        flex: 1;
    }

    .page-contributor-preview .section-doc-upload .cap-wrapper {width: 100%; padding: 0;}
    .page-contributor-preview .section-doc-upload .thead {
        display: none;
    }
    .page-contributor-preview .section-doc-upload .thead .th,
    .page-contributor-preview .section-doc-upload .tble-wrapper {
        border: 0;
    }
    .page-contributor-preview .section-doc-upload .tbody {
        background-color: transparent;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid rgba(112, 112, 112, 0.25);
        padding: 0 15px 15px;
    }
    .page-contributor-preview .section-doc-upload .tbody .td {
        display: block;
        border: 0;
        width: 100%;
        background-color: transparent;
        font-weight: 400;
        margin-bottom: 0;
        padding: 15px 0;
        padding-bottom: 0;
    }
    
    .page-contributor-preview .tab-label {
        font-size: 14px;
        font-weight: 600;
        color: #1F2937;
        margin-bottom: 10px;
    }
    .page-contributor-preview .section-photo-signature .ps-wrapper .form-caption {
        position: static;
        background-color: transparent;
        padding: 0;
        font-weight: 600;
        color: #1F2937;
    }
    
    .page-contributor-preview .section-photo-signature .cap-wrapper {
        padding: 0;
    }
    .page-contributor-preview .section-photo-signature .ps-wrapper .cap-wrapper {
        padding: 20px;
        border: 0;
        border-radius: 0;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: 1px solid rgba(42, 66, 110, 0.5);
    }
    .page-contributor-preview .section-photo-signature .ps-wrapper .cap-wrapper:last-child {
        border-bottom: 0;
    }
    .page-contributor-preview .section-photo-signature .ps-wrapper {
        margin: 0;
        flex-direction: column;
    }

    .page-reporter-details .rd-section .section-title {
        margin-bottom: 15px;
    }
    .page-reporter-details .rd-section .rd-table thead{
        display: none;
    }
    .page-reporter-details .rd-section .rd-table tbody tr {
        border-bottom: 1px solid #94A0B6;
    }
    .page-reporter-details .rd-section .rd-table tbody tr td {
        display: block;
        border: 0;
        text-align: left;
    }
    .page-reporter-details .table tbody tr td.address {
        padding-left: 15px;
        padding-right: 15px;
    }
    .admin-reporter-details .admin-tbl-wrapper {
        border: 0;
        border-radius: 0;
    }
    .admin-reporter-details .admin-report-tbl thead {
        display: none;
    }
    .admin-reporter-details .admin-report-tbl tbody tr {
        border-bottom: 1px solid rgba(112, 112, 112, 0.25);

    }
    .admin-reporter-details .admin-report-tbl tbody tr td {
        border: 0;
        background-color: transparent; 
        display: block;
        padding: 15px;
        padding-bottom: 0;
    }
    .admin-reporter-details .admin-report-tbl tbody tr td:last-child {
        padding-bottom: 15px;
    }
    .section-doc-upload .admin-ar-doc .attachment {
        width: 100%;
        min-width: 100%;
        min-height: 34px;
        margin-bottom: 10px;
    }
    .admin-report-tbl .ar-sel-reason {
        flex: 1;
        margin-right: 0;
    }
    .admin-report-tbl .ar-other-reason {
        margin-top: 15px;
        width: 100%;
    }
    .admin-reporter-details .admin-report-tbl .admin-ar-doc {
        flex-direction: flex;
        flex-wrap: wrap;
    }
    .admin-reporter-details .admin-report-tbl .admin-ar-doc .ar-toggler .check-w {
        width: auto;
        display: inline-block;
    }
    .admin-ar-ps {
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 20px;
    }
    .admin-ar-ps .ar-sel-reason {
        flex: 1;
        margin-right: 0;
    }
    .admin-ar-ps .ar-other-reason {
        width: 100%;
    }
    .file-modal-content {
        width: 100%;
        max-width: 600px;
    }
    .admin-reporter-details .submit-btn,
    .page-contributor-preview .btn-outline {
        width: auto;
        min-width: initial;
        padding:12px 24px;
        height: auto;
    }
    /* track page */
    .page-track-status .progress-bar {
        margin-bottom: 30px;
    }
    .page-contributor-preview.page-track-status .ps-wrapper .cap-wrapper {
        width: 100%;
        height: auto;
    }
    .page-contributor-preview.page-track-status .ps-wrapper .cap-wrapper + .cap-wrapper{
        border-bottom: 0;
    }
    .page-track-status .section-photo-signature .ps-status {
        padding-bottom: 20px;
    }
    .page-track-status .contract-wrapper {
        flex-direction: column;
    }
    .page-track-status .contract-wrapper .col-left {
        margin-bottom: 15px;
    }

    /*  */
    .page-reporter-details .card-body {
        padding-top: 0;
    }
    .page-reporters .table thead,
    .page-reporter-ddetails .table thead,
    .page-staff-list .table thead {display:none}
    .page-reporters .table,
    .page-reporters .table tbody,
    .page-reporters .table tr,
    .page-reporter-details .table,
    .page-reporter-details .table tbody,
    .page-reporter-details .table tr,
    .page-staff-list .table,
    .page-staff-list .table tbody,
    .page-staff-list .table tr,
    .page-reporters .table td,
    .page-staff-list .table td,
    .page-reporter-details .table td {display:block;width:100%;}
    .page-reporters .table tr,
    .page-staff-list .table tr,
    .page-reporter-details .table tr { border-bottom: 1px solid #D1D5DB; padding: 15px 0 0 0;}
    .page-reporter-details .table {
        border: 1px solid #D1D5DB;
    }
    .page-reporter-details .table tr {
        border: 0;
    }
    .page-reporters .table td,
    .page-staff-list .table td,
    .page-reporter-details .table td {text-align:right;padding-left:50%;text-align:right;position:relative}
    .page-reporters .table td::before,
    .page-staff-list .table td::before,
    .page-reporter-details .table td::before {content:attr(data-label);position:absolute;left:0;top: 0; width:50%;padding-left:15px;font-size:15px;font-weight:600;text-align:left}
    .val { display: flex; justify-content: flex-start; width: 100%; text-align: start; margin-top: 15px; font-weight: 400;}
    .page-reporters .table tbody tr td { border-bottom: none; display: flex; justify-content: flex-start; padding: 25px 0 10px 15px;}
    .page-reporter-details .md-section { width: 100%; max-width: 100%;}
    .reporter-thumb { margin: 15px 0 15px 0; }
    .page-reporter-details .reporter-name { text-align: start;}
    .contributor-form.doc-section .form-cap-card{width: 100%;}
    .page-reporter-details .table tbody tr td .created-date { text-align: start;}
    .page-reporter-details .form-action { margin-top: 30px;}
    .page-reporter-details .table tbody tr td { border: none;}
    .page-reporter-details .table tbody tr { border: 1px solid #D1D5DB;}
    .page-staff-list .table tbody tr td { border-bottom: none; display: flex; justify-content: flex-start; padding: 30px 0 15px 15px;}
    .page-staff-list .date { display: flex; flex-direction: column; align-items: center;}
    .page-reporter-details .ar-uploaded-section .upload-cc-wrapper { 
        flex-wrap: wrap; 
        
    }
    .page-reporter-details .ar-uploaded-section .upload-cc-wrapper .temp-upload-file {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
    }
    .page-reporter-details .ar-uploaded-section .upload-cc-wrapper .reason-sel {
        flex: 1;
        margin-right: 0;
    }
    .page-reporter-details .ar-uploaded-section .other-reason {
        max-width: 100%;
        margin-top: 10px;
    }
    .md-section .formGroup .input-control { width: 100%;}

    .page-staff-list .tr_active td:first-child:before{content:none}
    .page-staff-list .tr_active + .tr_toggle td:first-child:before{content:none}
    .page-staff-list .tr_toggle .table-details{flex-direction:column;gap:20px;padding:0 10px}
    .page-staff-list .table tbody tr.tr_toggle td:first-child{width:100%}
    .page-staff-list .tblflx td:first-child {
        position: absolute; 
        right: 10px; 
        display: block;
        top: 50%;
        margin-top: -12px;
    }
    .page-staff-list .tr_toggle_action {display: flex; align-items: center; justify-content: center; line-height: normal;}
    .page-staff-list .tr_toggle_action .fi {line-height: normal;}
    .page-staff-list .table tr.tr_toggle {display: none; position: relative;}
    .page-staff-list .table tr.tr_active + .tr_toggle { display: block; width: 100%; }
    .page-staff-list .pagination-strip { flex-direction: column;}
    .page-staff-list .table tr { padding: 0px;}
    .page-staff-list .table tr.tblflx {position: relative; padding-right: 54px !important;}
    .page-staff-list .table tr.tr_toggle td { padding: 20px; justify-content: center;}
    .page-staff-list .table tr.tr_toggle .date .val { margin-top: 5px;}
    .page-staff-list .date + .date { margin-top: 15px;}
    .staff-modal-content { width: 98%;}
    .staff-mbody .staff-form { width: 100%;}
    .staff-mbody .staff-form .formflx { display: flex; align-items: center; flex-direction: inherit;}
    .staff-mbody .form-horizontal .formGroup label {margin-top: 0; margin-bottom: 5px;}
    .staff-mbody .form-horizontal .input-control.w-half {width: 100%;}
    .formflx .form-wrapper .input-control.w-half { width: 100%;}
    .page-staff-list .tblflx { display: flex; flex-direction: column; padding: 15px 0 !important;}
    .page-staff-list .tblflx td:first-child { order: 10; padding: 0px;}
    .page-staff-list .tblflx .tr_toggle_action{  background-color: #2A426E; box-shadow: 3px 3px 6px rgba(0,0,0,0.42); border-radius: 3px; color: #ffffff; position: relative;}
    .page-staff-list .tblflx .tr_toggle_action .fi { color: #ffffff;}
    .page-reporter-details .rd-section .rd-table thead { display: none;}
    .page-reporter-details .form-action { margin-top: 30px;}
    .page-reporters .pagination-strip { flex-direction: column; padding: 0px 0px 20px; gap: 10px;}
    .page-reporters .table tbody tr td.action { display: flex; justify-content: center; padding-bottom: 20px;}
    .page-reporters .action-btn { padding: 10px 60px; background-color: #2A426E; box-shadow: 3px 3px 6px rgba(0,0,0,0.42); border-radius: 10px; color: #ffffff;}
    .management-details .formGroup .lab-visible-mob { display: block; text-align: start; margin-bottom: 5px ;}
    .mbtm .table tbody tr td .val {margin-top: 10px;}
    .mbtm .table tbody tr td:last-child .val { margin-bottom: 0;}
    .page-staff-list .tr_active:first-child:before {
        content: '';
        position: absolute;
        left: 0;
        width: 6px;
        height: 100%;
        top: 0;
        background-color: #0A0A0A;
        border-top-left-radius: 10px;
    }
    .page-staff-list .table  tr.tr_active {border-bottom: 0;}
    .page-staff-list .tr_active + .tr_toggle:before {
        content: '';
        position: absolute;
        left: 0;
        width: 6px;
        height: 100%;
        top: 0;
        background-color: #0A0A0A;
        border-bottom-left-radius: 10px;
        z-index: 1;
    }
  
}   


#vendor_code {
    border: 2px solid #6325f3;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 8px;

  }
  #vendor_submit {
    background-color: #31393d;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top:15px;
    border-radius: 8px;
    cursor: pointer;
  }

.contributor-form .form-col-2 {
    display: flex;
    justify-content: space-between;
}
.hidden {
    display: none;
}

.page-reporter-details .preview-component .card-body {
    padding: 0;
}
.page-reporter-details .section-doc-upload {
    width: 100%;
    margin: 0;
    justify-content: flex-start;
}
.page-reporter-details .page-contributor-preview .cap-wrapper.w-full {
    margin-left: 0;
    margin-right: 0;
}
.page-reporter-details .attachment-name {
    position: relative;
    padding-right: 44px;
    flex: 1;
    word-break: break-all;
    width: 200px;
    max-width: 200px;
}
.page-reporter-details .prof-name {
    word-break: break-all;
}
.page-reporter-details .attachment-action {
    position: absolute;
    right: 0;
    top: 50%;
    display: flex;
    margin-top: -11px;
}
.page-reporter-details .attachment-action .download-icon {color: #22831c;}
.page-reporter-details .attachment-action .download-icon, .page-reporter-details .attachment-action .fi-visibility-black {
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
}
.page-reporter-details .signature-card,
.page-reporter-details .photograph-card {
    text-align: center;
}
.page-reporter-details .section-photo-signature .image-preview {margin-bottom: 10px;}
.section-photo-signature .download-action {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}
.section-photo-signature .download-action .download-icon {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    text-align: center;
    line-height: 32px;
    background-color: rgba(255,255,255,.8);
    color: #22831c;
}
.page-reporter-details .preview-component .section-photo-signature {
    margin-left: 0;
    margin-right: 0;
}
@media (max-width: 769px) {
    .page-reporter-details .attachment-name,
    .admin-report-tbl .attachment-name {
        width: 100%;
        max-width: 100%;
    }
    .form-regular .formGroup .col-6 {width: 100%;}
    .form-regular .formGroup {flex-direction: column; align-items: flex-start;}
    .form-regular .formGroup .col-6:first-child {
        margin-bottom: 15px;
    }
}

