/** @font-faces **/
@font-face {
    font-family: 'Roboto';
    src: url('../../../public/fonts/Roboto-Bold.woff2') format('woff2'),
        url('../../../public/fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../public/fonts/Roboto-Medium.woff2') format('woff2'),
        url('../../../public/fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../public/fonts/Roboto-Regular.woff2') format('woff2'),
        url('../../../public/fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Light.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../public/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../../../public/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/** Custom Font Icons **/
@font-face {
    font-family: 'files18';
    src:  url('../../../public/fonts/files18.eot?470lm4');
    src:  url('../../../public/fonts/files18.eot?470lm4#iefix') format('embedded-opentype'),
      url('../../../public/fonts/files18.ttf?470lm4') format('truetype'),
      url('../../../public/fonts/files18.woff?470lm4') format('woff'),
      url('../../../public/fonts/files18.svg?470lm4#files18') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .fi {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'files18' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .fi-arrow-left-short:before {
    content: "\e90d";
  }
  .fi-search-black:before {
    content: "\e90e";
  }
  .fi-visibility-black-o:before {
    content: "\e90f";
  }
  .fi-unpublished-black:before {
    content: "\e910";
  }
  .fi-verified-black:before {
    content: "\e911";
  }
  .fi-chevron-right-black:before {
    content: "\e912";
  }
  .fi-checkbox-unchecked:before {
    content: "\e906";
  }
  .fi-form:before {
    content: "\e900";
  }
  .fi-settings-black:before {
    content: "\e901";
  }
  .fi-drive-file-rename-o-black:before {
    content: "\e902";
  }
  .fi-person-black:before {
    content: "\e903";
  }
  .fi-calendar-month-black:before {
    content: "\e904";
  }
  .fi-checkbox-black:before {
    content: "\e905";
  }
  .fi-file-copy-black:before {
    content: "\e907";
    color: #2a426e;
  }
  .fi-visibility-black:before {
    content: "\e908";
    color: #2a426e;
  }
  .fi-file-download:before {
    content: "\e909";
  }
  .fi-close-black:before {
    content: "\e90a";
  }
  .fi-close:before {
    content: "\e90b";
  }
  .fi-upload-documnet:before {
    content: "\e90c";
  }
  
  

/** Reset CSS **/
html{height:100%;scroll-behavior: smooth;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
body,*{padding:0;margin:0;-webkit-overflow-scrolling:touch;font-family:'Open Sans', sans-serif;box-sizing:border-box;}
ol,ul,li{list-style:none;padding:0;margin:0}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}
a{text-decoration:none;outline:none}
a:focus,img:focus,input:focus,button:focus,div:focus{outline:none;}
*, ::after, ::before {box-sizing:border-box;}

/**  **/
body {
    background-color: #f1f3f6;
    font-family: 'Roboto', 'Open Sans', sans-serif;
}

/* Auth */
.auth-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #12223c;
    background-image: url('../../../public/img/file18-bg.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
.auth-wrapper .intro-creative {
    margin-top: 80px;
    margin-bottom: 60px;
    display: block;
    text-align: center;
    width: 170px;
    margin-left: auto;
    margin-right: auto;
}
.login-page .auth-wrapper .intro-creative {
    margin-bottom: 100px;
}
.creative-logo {
    width: 162px;
    margin: 0 auto;
    display: block;
}
.creative-logo img {
    width: 100%;
    overflow: hidden;
    display: block;
}
.auth-wrapper .greeting-text {
    font-family:'Times New Roman', Times, serif;
    line-height: normal;
    color: #fff;
    margin-top: 20px;
}
.auth-wrapper .greet {
    text-transform: uppercase;
    font-size: 17px;
}
.auth-wrapper .company {
    font-size: 28px;
}
.auth-form-wpr {
    margin-bottom: 30px;
}
.auth-form-wpr .form-w {
    padding: 0 12px;
    margin-right: auto;
    margin-left: auto;
    flex: 0 0 auto;
    width: 100%;
    max-width: 522px;
}

.auth-form-wpr .form-card {
    padding: 24px 41px 60px;
    background-color: #fff;
    border-radius: 20px;
}
.auth-form-wpr .greeting {
    text-align: center;
    margin-bottom: 25px;
}
.auth-form-wpr .greeting h2 {
    font-weight: bold;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    color: #033333;
    margin-bottom: 10px;
}
.auth-form-wpr .otp-fr-text {
    text-align: center;
    margin-bottom: 100px;
}
.auth-form-wpr .otp-fr-text h2 {
    font-weight: bold;
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    color: #033333;
    margin-bottom: 5px;
}
.auth-form-wpr .otp-fr-text a {color: #2A426E; text-decoration: underline;}
.auth-form-wpr .greeting h3 {
    font-weight: normal;
    font-size: 16px;
    color: #033333;
}
.auth-form-wpr .fc {
    display: block;
    width: 100%;
    padding: 12px 14px;
    font-size: 16px;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #FAFAFA;
    background-clip: padding-box;
    border: 1px solid #033333;
    appearance: none;
    border-radius: 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.auth-form-wpr .fc::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #033333;
    opacity: 0.5;
}
.auth-form-wpr .fc::-moz-placeholder { /* Firefox 19+ */
    color: #033333;
    opacity: 0.5;
}
.auth-form-wpr .fc:-ms-input-placeholder { /* IE 10+ */
    color: #033333;
    opacity: 0.5;
}
.auth-form-wpr .fc:-moz-placeholder { /* Firefox 18- */
    color: #033333;
    opacity: 0.5;
}
.auth-form-wpr .fg {
    margin-bottom: 18px;
}
.auth-form-wpr .form-action {margin-top: 80px; text-align: center; margin-bottom: 0;}
.page-otp-verify .auth-form-wpr .form-action {margin-top: 120px; margin-bottom: 30px;}
.auth-form-wpr .submit-btn, .signupform .submit-btn{
    background-color: #2A426E;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    color: #fff;
    padding: 12px 15px;
    width: 100%;
    max-width: 200px;
    border: 0;
    text-transform: uppercase;
    border-radius: 10px;
    cursor: pointer;
}
.auth-form-wpr .submit-btn:hover {
    opacity: 0.8;
}

.pwd-wpr {
    position: relative;
}
.pwd-wpr .pi-wpr {position: absolute; right: 0; top: 0; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; cursor: pointer;}
.pwd-wpr .pi-wpr .fi {font-size: 24px; opacity: 0.5; line-height: normal;}
.pwd-wpr .pi-wpr:hover .fi {opacity: 1;}

.otpField {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.page-otp-verify .auth-form-wpr .input {
    height: 60px;
    width: 60px;
    border: 1px solid #ABABAB;
    outline: none;
    text-align: center;
    font-size: 1.5em;
    border-radius: 10px;
    background-color: #ffffff;
    outline: none;
    /*Hide number field arrows*/
    -moz-appearance: textfield;
}
.page-otp-verify input[type="number"]::-webkit-outer-spin-button,
.page-otp-verify input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.resend-wpr p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
}
.resend-wpr .resend-text {
    font-weight: bold;
    color: #2A426E;
    cursor: pointer;
}
.resend-wpr .resend-text:hover {
    opacity: 0.8;
}

/** Login Form **/
.login-page .auth-form-wpr .form-action {margin-top: 28px; margin-bottom: 30px;}
.cf-wrap {display: flex; justify-content: space-between;}
.cf-wrap .forgot-link a {color: #707070; font-family: 'Roboto', 'Open Sans', sans-serif; font-size: 14px;}
.cf-wrap .forgot-link a:hover {color: #2A426E;}
.no-account-text {text-align: center; color: #033333; font-size: 16px;}
.no-account-text a {color: #2A426E;}
.login-page .auth-form-wpr .form-card {padding-top: 36px; padding-bottom: 40px;}
.custom-check input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.custom-check .form-check-label {position: relative; font-size: 14px; padding-left: 26px; cursor: pointer;}
.custom-check .form-check-label:before { 
    font-family: 'files18' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e906';
    font-size: 18px;
    position: absolute;
    left: 0;
    top: -2px;
    color: #2A426E;
}
.custom-check input[type="checkbox"]:checked + .form-check-label:before {
    content: '\e905';
}
@media screen and (max-width: 767px) {
    .auth-wrapper .intro-creative { 
        margin-top: 54px;
        margin-bottom: 27px; 
    }
    .auth-form-wpr .form-card {
        padding: 44px 20px 30px;
    }
    .auth-form-wpr .greeting h2 {
        font-size: 20px;
    }
    .auth-form-wpr .greeting h3 {
        font-size: 16px;
    }
    .auth-form-wpr .fc {
        font-size: 16px;
    }
    .auth-form-wpr .fg {
        margin-bottom: 15px;
    }
    .auth-form-wpr .form-action {
        margin-bottom: 0;
        margin-top: 60px;
    }
    .auth-form-wpr .submit-btn {
        font-size: 16px;
        max-width: 180px;
    }
    .auth-form-wpr .otp-fr-text {
        margin-bottom: 120px;
    }
    .auth-form-wpr .otp-fr-text h2 {
        font-size: 18px;
        line-height: 26px;
    }
    .page-otp-verify .otpField {justify-content: space-around;}
    .page-otp-verify .auth-form-wpr .input {
        height: 40px;
        width: 40px;
        font-size: 17px;
    }
    .page-otp-verify .auth-form-wpr .form-action {
        margin-top: 120px;
        margin-bottom: 30px;
    }
    .resend-wpr p {font-size: 16px;}
    .login-page .auth-wrapper .intro-creative {margin-bottom: 27px;}
    .login-page .auth-form-wpr .form-card {padding-top: 44px; padding-bottom: 30px;}
    .auth-form-wpr .greeting {margin-bottom: 46px;}
    .login-page .auth-form-wpr .greeting h2 {margin-bottom: 12px;}
    .login-page .auth-form-wpr .form-action {margin-top: 75px; margin-bottom: 60px;}
  }